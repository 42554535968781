import { createSlice } from "@reduxjs/toolkit";

export const currentLocationSlice = createSlice({
  name: "currentLocation",
  initialState: {
    value: {
      current: null,
      prior: null,
    },
  },
  reducers: {
    updateLocation: (state, action) => {
      if (
        !state.value.prior ||
        (state.value.current && state.value.prior.id !== state.value.current.id)
      )
        state.value.prior = state.value.current;
      state.value.current = action.payload;
    },
  },
});

export const { updateLocation } = currentLocationSlice.actions;

export const selectLocation = (state) => state.currentLocation.value;

export default currentLocationSlice.reducer;
