import React from "react";
import MapDetails from "./MapDetails/MapDetails";
import Map from "./Map/Map";
import { Container, Row, Col, Navbar, NavbarBrand } from "react-bootstrap";
import Footer from "./Footer/Footer";
import DataDropDown from "./DataDropDown/dataDropDown";

const Desktop = () => {
  return (
    <div>
      <Navbar
        bg="info"
        expand="lg"
        style={{
          background: 'url("navbar.png")', // Replace with the actual path to your image
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
        }}
      >
        <Navbar.Brand
          href="/"
          style={{
            marginLeft: "20px",
            backgroundColor: "#58fcfc",
            borderRadius: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          USA Cost Watch
        </Navbar.Brand>
        <NavbarBrand style={{
            marginLeft: "10px",
            backgroundColor: "#58fcfc",
            borderRadius: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}>
            Cost of Living Accross USA Metro Areas
        </NavbarBrand>
      </Navbar>
      <Container fluid>
        <Row fluid>
          <Col
            md={4}
            style={{
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <MapDetails />
          </Col>
          <Col
            md={8}
            style={{
              height: "93vh",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Container
              style={{
                height: "100%",
                width: "100%",
                margin: "0",
                padding: "0",
                backgroundColor: "lightblue",
              }}
            >
              <Row
                style={{
                  textAlign: "center",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  margin: 0
                }}
              >
                <DataDropDown/>
              </Row>
              <Row style={{ width: "66vw", height: "90vh", marginLeft: 0 }}>
                <Map style={{ margin: 0, padding: 0 }} />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </div>
  );
};

export default Desktop;
