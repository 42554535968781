import React from "react";
import { isMobile } from "./StateManagement/getMobileSite";
import Mobile from "./mobile";
import Desktop from "./desktop";

const Home = () => {
  if (isMobile()) {
    return <Mobile></Mobile>;
  } else {
    return <Desktop></Desktop>;
  }
};

export default Home;
