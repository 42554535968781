import { configureStore } from "@reduxjs/toolkit";
import currentLocationReducer from "./StateManagement/currentLocationSlice";
import statisticSourceReducer from "./StateManagement/statisticSourceSlice";

export default configureStore({
  reducer: {
    currentLocation: currentLocationReducer,
    statisticSource: statisticSourceReducer,
  },
});
