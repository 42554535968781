import React from "react";
import { useCombobox } from "downshift";
import cx from "classnames";
import { updateLocation } from "../StateManagement/currentLocationSlice";
import useLocationList from "../Clients/useLocationList";
import { useDispatch } from "react-redux";

function LocationSearch() {
  let [locations] = useLocationList();
  const dispatch = useDispatch();

  function getlocationsFilter(inputValue) {
    const lowerCasedInputValue = inputValue.toLowerCase();

    return function locationsFilter(location) {
      return (
        !inputValue ||
        location.name.toLowerCase().includes(lowerCasedInputValue)
      );
    };
  }

  function ComboBox() {
    const [items, setItems] = React.useState(
      locations && locations.getLocationList()
    );
    const {
      isOpen,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      getItemProps,
      selectedItem,
    } = useCombobox({
      onInputValueChange({ inputValue }) {
        setItems(
          locations.getLocationList().filter(getlocationsFilter(inputValue))
        );
      },
      onSelectedItemChange: ({ selectedItem }) => {
        dispatch(updateLocation(selectedItem));
      },
      items,
      itemToString(item) {
        return item ? item.name : "";
      },
    });

    return (
      <div style={{ position: "relative", zIndex: "1000" }}>
        <div>
          <div>
            <input
              style={{ width: "100%" }}
              placeholder="Search . . ."
              {...getInputProps()}
            />
          </div>
        </div>
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: "0",
            zIndex: "1000",
            width: "100%",
            border: `${isOpen && items.length && "solid"}`,
          }}
          className={`absolute bg-white mt-1 shadow-md max-h-80 p-0 z-10 ${
            !(isOpen && items.length) && "hidden"
          }`}
          {...getMenuProps()}
        >
          {isOpen &&
            items.slice(0, 5).map((item, index) => (
              <li
                className={cx(
                  highlightedIndex === index && "bg-blue-300",
                  selectedItem === item && "font-bold",
                  "py-2 px-3 shadow-sm flex flex-col"
                )}
                key={item.id}
                {...getItemProps({ item, index })}
              >
                <span>{item.name}</span>
              </li>
            ))}
        </ul>
      </div>
    );
  }
  return <ComboBox />;
}

export default LocationSearch;
