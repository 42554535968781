import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLegend } from "../StateManagement/statisticSourceSlice";

const useLegend = () => {
  const [data, setData] = useState(null);
  const legend = useSelector(selectLegend);

  useEffect(() => {
    setData(legend);
  }, [legend]);

  return [data, setData];
};

export default useLegend;
