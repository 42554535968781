import { useState, useEffect } from "react";
import locationList from "../Data/US_CostLiving_LocationList.json";

class Locations {
  constructor(locations) {
    this.locations = locations;
    this.locationDict = {};
    this.locations.forEach((location) => {
      this.locationDict[location.id] = location;
    });
  }

  getLocationList() {
    return this.locations;
  }

  getLocation(id) {
    return this.locationDict[id];
  }
}

const useLocationList = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const locations = new Locations(locationList);
    setData(locations);
  }, []);

  return [data, setData];
};

export default useLocationList;
