import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { selectLocation } from "../StateManagement/currentLocationSlice";
import useLocationList from "../Clients/useLocationList";
import MapDetail from "./MapDetail/MapDetail";
import LocationSearch from "./LocationSearch";
import { selectStatistic } from "../StateManagement/statisticSourceSlice";

const SortingList = () => {
  const statistic = useSelector(selectStatistic);

  const executeScroll = () => {
    if (locationRef.current)
      locationRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const sortLocations = () => {
    if (locations) {
      setSortDirection(!sortDirection);
      tableRef.current.scrollIntoView();
    }
  };

  const [locations] = useLocationList();
  const [sortDirection, setSortDirection] = useState(false);
  const locationRef = useRef(null);
  const tableRef = useRef(null);

  if (locations) {
    if (sortDirection)
      locations.getLocationList().sort((a, b) => a[statistic.statistic]- b[statistic.statistic]);
    else
      locations.getLocationList().sort((a, b) => b[statistic.statistic] - a[statistic.statistic]);
  }

  const location = useSelector(selectLocation);

  const currentLocation = location.current;
  const priorLocation = location.prior;

  useEffect(executeScroll);

  return (
    <Table ref={tableRef}>
      <thead
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "#fff",
        }}
      >
        <tr key="search" >
          <th colSpan={3}>
            <LocationSearch></LocationSearch>
          </th>
        </tr>
        <tr key="title">
          <th>Metro Area</th>
          <th> {statistic.shortDescription}</th>
          <th>
            <button
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
              onClick={sortLocations}
            >
              {sortDirection ? (
                <i className="bi bi-sort-down"></i>
              ) : (
                <i className="bi bi-sort-up"></i>
              )}
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {locations &&
          locations.getLocationList().map((item) => {
            if (currentLocation && item.id === currentLocation.id) {
              return MapDetail(
                item,
                statistic.statistic,
                statistic.preUnit,
                statistic.postUnit,
                locationRef,
                true,
                false
              );
            }
            if (priorLocation && item.id === priorLocation.id) {
              return MapDetail(
                item,
                statistic.statistic,
                statistic.preUnit,
                statistic.postUnit,
                null,
                false,
                true
              );
            }
            return MapDetail(
              item,
              statistic.statistic,
              statistic.preUnit,
              statistic.postUnit,
              null,
              false
            );
          })}
      </tbody>
    </Table>
  );
};

export default SortingList;
