import { createSlice } from "@reduxjs/toolkit";
import { statistics } from "../Data/statisticSource";

export const statisticSourceSlice = createSlice({
  name: "statisticSource",
  initialState: {
    statistic: statistics.rent,
  },
  reducers: {
    updateStatisticSource: (state, action) => {
      state.statistic = action.payload;
    },
  },
});

export const { updateStatisticSource } = statisticSourceSlice.actions;

export const selectStatistic = (state) => state.statisticSource.statistic;

export const selectLegend = (state) => [
  ...state.statisticSource.statistic.legendList,
];

export default statisticSourceSlice.reducer;
