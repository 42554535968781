import { useSelector } from "react-redux";
import { selectStatistic } from "../StateManagement/statisticSourceSlice";
import MapLegendArrow from "./MapLegendArrow";

const MapLegend = () => {
  const statistic = useSelector(selectStatistic);
  return (
    <div>
    <table
      style={{
        backgroundColor: "#fff",
        maxWidth: "120px",
        borderRadius: "10px",
      }}
    >
      <thead>
        <tr>
          <th style={{ textAlign: "center"}} colSpan="2">
            {statistic.description}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>             
            <MapLegendArrow></MapLegendArrow>
          </td>
        </tr>
      </tbody>
    </table>
    <div> usacostwatch.com </div>
    </div>
  );
};

export default MapLegend;
