import React from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStatistic,
  updateStatisticSource,
} from "../StateManagement/statisticSourceSlice";
import { statistics } from "../Data/statisticSource";

const DataDropDown = () => {
  const statistic = useSelector(selectStatistic);
  const dispatch = useDispatch();

  // Function to create onClick handler for a statistic key
  const createOnClickHandler = (statisticKey) => () => {
    dispatch(updateStatisticSource(statistics[statisticKey]));
  };

  // Generate Dropdown items dynamically
  const dropdownItems = Object.keys(statistics).map((statKey) => (
    <Dropdown.Item key={statKey} onClick={createOnClickHandler(statKey)}>
      {statistics[statKey].description}
    </Dropdown.Item>
  ));

  return (
    <Dropdown >
      <Dropdown.Toggle id="dropdown-basic">
        {statistic.description}
      </Dropdown.Toggle>

      <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
    </Dropdown>
  );
};

export default DataDropDown;