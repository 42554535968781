import { updateLocation } from "../../StateManagement/currentLocationSlice";
import { useDispatch } from "react-redux";
import { isMobile } from "../../StateManagement/getMobileSite";
import { statistics } from "../../Data/statisticSource";

const MapDetail = (
  location,
  statistic,
  preUnit = "",
  postUnit = "",
  locationRef = null,
  active = false,
  prior = false,
  isSingle = false
) => {
  const dispatch = useDispatch();
  const activeBackgroundColor = "#ccf9ff";

  let getCell = (value) => {
    if (isSingle) {
      return <th>{value}</th>;
    } else {
      return <td>{value}</td>;
    }
  };

  return (
    <>
      <tr
        onClick={() => dispatch(updateLocation(location))}
        ref={locationRef}
        key={location.id}
        style={{
          backgroundColor: active
            ? activeBackgroundColor
            : prior
            ? "#f1f0f0"
            : null,
          fontSize: isMobile() ? "15px" : "20px",
        }}
      >
        {getCell(location.name)}
        {getCell(
          `${preUnit}${parseFloat(location[statistic]).toLocaleString()}${postUnit}`
        )}
        {getCell(
          <i
            className="bi bi-circle-fill"
            style={{ color: location.colors[statistic] }}
          ></i>
        )}
      </tr>
      {active && (
        <tr
          key={location.id + "-detail"}
          style={{ backgroundColor: activeBackgroundColor }}
        >
          <td colSpan={3}>
          {Object.keys(statistics).map(key => {
            return <div>{statistics[key].description}: {statistics[key].preUnit}{parseFloat(location[key]).toLocaleString()}{statistics[key].postUnit}</div>
            })
            }
          </td>
        </tr>
      )}
    </>
  );
};

export default MapDetail;
